import React from "react";
import starImage from "../../images/star.svg";

import { useState } from "react";
import EmailForm from "../EmailForm/EmailForm";

const Popup = ({
  onClose,
  className,
  handleBuyPremium,
  handleStarPremium,
  userEmail,
}) => {
  const [buttonColor, setButtonColor] = useState(null);
  const [buttonBotColor, setButtonBotColor] = useState(null);
  const [isShownForm, setIsShownForm] = useState(false);

  const [payload, setPayload] = useState("");

  const handleShowEmailForm = () => {
      setIsShownForm(true);
  };

  const handleCloseEmailForm = () => {
    setIsShownForm(false);
    setPayload("");
  };

  const handleClickTop = () => {
    setPayload("twoWeeksSubscriptionPayload");
    setButtonColor("rgba(141, 26, 5, 1)");
    if (userEmail !== "example@mail.ru") {
      handleBuyPremium("twoWeeksSubscriptionPayload", userEmail, false);
      handleCloseShop();
    } else {
      handleShowEmailForm();
    }
    setTimeout(() => {
      setButtonColor(null);
    }, 300);
  };

  const handleClickBot = () => {
    setButtonBotColor("rgba(141, 26, 5, 1)");
    setPayload("monthlySubscriptionPayload");
    if (userEmail !== "example@mail.ru") {
      handleBuyPremium("monthlySubscriptionPayload", userEmail, false);
      handleCloseShop();
    } else {
      handleShowEmailForm();
    }
    setTimeout(() => {
      setButtonBotColor(null);
    }, 300);
  };

  const handleCloseShop = () => {
    setTimeout(() => {
      onClose();
      setPayload("");
    }, 30);
  };

  return (
    <div className={`popup-shop ${className}`}>
      <p className="popup-shop__header">Наши предложения</p>
      <button className="popup-shop__close-button" onClick={onClose}></button>
      <div className="popup-shop__content">
        <div className="popup-shop__title-box">
          <p className="popup-shop__title">Укусить яблоко</p>
          <p className="popup-shop__title popup-shop__title-red">149 ₽</p>
        </div>
        <ul className="popup-shop__list">
          <li className="popup-shop__list-item">
            Неограниченное количество раскладов с трактовками в течение 14 дней
          </li>
          <li className="popup-shop__list-item">
            Сохранять результаты раскладов
          </li>
        </ul>
        <button
          className="popup-shop__button"
          style={{ backgroundColor: buttonColor }}
          onClick={() => {
            handleClickTop();
            handleShowEmailForm();
          }}
        >
          Купить
        </button>
      </div>
      <div className="popup-shop__content">
        <div className="popup-shop__title-box">
          <p className="popup-shop__title">Съешь меня</p>
          <p className="popup-shop__title popup-shop__title-red">249 ₽</p>
        </div>
        <ul className="popup-shop__list">
          <li className="popup-shop__list-item">
            Неограниченное количество раскладов с трактовками в течение месяца
          </li>
          <li className="popup-shop__list-item">
            Сохранять результаты раскладов
          </li>
        </ul>
        <button
          className="popup-shop__button"
          onClick={() => {
            handleClickBot();
            handleShowEmailForm();
          }}
        >
          Купить
        </button>
      </div>
      <div className="popup-shop__content">
        <div className="popup-shop__title-box">
          <p className="popup-shop__title">Млечный путь</p>
          <img
            alt="Звёздочка"
            src={starImage}
            className="popup-shop__star-image"
          />
        </div>
        <ul className="popup-shop__list">
          <li className="popup-shop__list-item">
            Оплата 30 дней доступа звездами Telegram
          </li>
        </ul>
        <button
          className="popup-shop__button"
          style={{ backgroundColor: buttonBotColor }}
          onClick={() => {
            handleStarPremium();
            handleClickBot();
            handleCloseShop();
          }}
        >
          Купить
        </button>
      </div>
      {isShownForm && (
        <EmailForm
          onClose={handleCloseEmailForm}
          handleClose={handleCloseShop}
          handleBuyPremium={handleBuyPremium}
          payload={payload}
          userEmail={userEmail}
          className="email-form"
        />
      )}
    </div>
  );
};

export default Popup;
