import { BASE_URL } from "./constances";

class Api {
  constructor(data) {
    this._url = data.url;
  }

  _checkResponse(resp) {
    if (!resp.ok) {
      return resp.json().then((errorData) => {
        const errorMessage = resp.status;
        console.log(errorMessage);
        return Promise.reject(errorMessage);
      });
    }
    return resp.json();
  }
  // userName: "Piper", //PROD
  // userId: 509870044,
  // cardsData: "Маг, Башня, Повешенный",
  // question: "Как не допустить новых ошибок?",

  // userName: user.first_name,                                                                            //PROD
  // userId: user.id,
  // cardsData: data,
  // question: question,

  getInterpretation(user, data, question) {
    const requestBody = {
      userName: user.first_name, //PROD
      userId: user.id,
      cardsData: data,
      question: question,
    };
    return fetch(`${this._url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then((resp) => this._checkResponse(resp));
  }
}

const GigaApi = new Api({
  url: `${BASE_URL}/neuro/interpretation`,
});

export default GigaApi;
