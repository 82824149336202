import React, { useContext, useState, useEffect, useRef } from "react";
import Card from "../Card/Card.jsx";
import NavMenu from "../NavMenu/NavMenu.jsx";
import PopupCard from "../PopupCard/PopupCard.jsx";
import CurrentUserContext from "../../context/CurrentUserContext.js";
import * as MainApi from "../../utils/MainApi.js";
import PopupShop from "../PopupShop/PopupShop.jsx";
import PopupAlert from "../PopupAlert/PopupAlert.jsx";

const Deck = ({
  isLoggedIn,
  deck,
  drawnCards,
  inputQuestion,
  aiDescription,
  shuffled,
  currentCard,
  isLoading,
  onShuffleDeck,
  onDrawCard,
  onResetGame,
  onCardClick,
  onClosePopup,
  cardNames,
  isOver,
  handleSubmit,
  onInputChange,
  isButtonClicked,
  userData,
  inputRef,
  isCardClicked,
  shownCard,
  isShowShop,
  email,
  isRequests,
}) => {
  const currentUser = useContext(CurrentUserContext);
  const [inputHolder, setInputHolder] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [buttonColor, setButtonColor] = useState(null);

  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const [isAlertVisible, setIsAlertVisible] = useState(false); // Алерт попап
  const [isAnimatedAlert, setIsAnimatedAlert] = useState(false); //
  const [alertMessage, setAlertMessage] = useState(""); // Текст Алерта

  const [useMask, setUseMask] = useState(true); // Статус применения маски
  const descriptionRef = useRef(null);

  const [isInputFocused, setInputFocused] = useState(false);

  const handleShowAlert = (message) => {
    setAlertMessage(message);
    setIsAlertVisible(true);
    toggleAlert();
  };

  const handleClickLeft = () => {
    setButtonColor("rgba(219, 48, 17, 1)");
    setTimeout(() => {
      setButtonColor(null);
    }, 300);
  };

  const toggleAlert = () => {
    setTimeout(() => {
      setIsAnimatedAlert(true);
    }, 50);
    setTimeout(() => {
      setIsAnimatedAlert(false);
    }, 2000);
    setTimeout(() => {
      setAlertMessage("");
      setIsAlertVisible(false);
    }, 2400);
  };

  const handleClickRight = () => {
    setButtonColor("rgba(141, 26, 5, 1)");
    setTimeout(() => {
      setButtonColor(null);
    }, 300);
  };

  const handleSave = async () => {
    if (isLoggedIn) {
      //PROD
      try {
        await MainApi.saveCards(
          currentUser.id,
          cardNames,
          aiDescription,
          inputQuestion
        );
        handleShowAlert("Успешно отправлено Вам!");
      } catch (error) {
        handleShowAlert(error.message || "Что-то пошло не так");
      }
    } else {
      handleShowAlert("Пользователь не авторизован.");
    }
  };

  const handleGetPrem = () => {
    setPopupVisible(true);
    setTimeout(() => {
      setIsAnimated(true);
    }, 100);
  };

  const handleStarPremium = async () => {
    if (isLoggedIn) {
      //PROD
      try {
        await MainApi.getStarSubscription(
          currentUser.id,
          "monthlySubscriptionPayload"
        );
        handleShowAlert("Вам отправлен счет");
      } catch (error) {
        handleShowAlert(error.message || "Что-то пошло не так");
      }
    } else {
      handleShowAlert("Пользователь не авторизован.");
    }
  };

  const handleBuyPremium = async (payload, email, isSave) => {
    if (isLoggedIn) {
      //PROD
      try {
        await MainApi.getSubscription(currentUser.id, payload, email, isSave);
        handleShowAlert("Вам отправлен счет");
      } catch (error) {
        handleShowAlert(error.message || "Что-то пошло не так");
      }
    } else {
      handleShowAlert("Пользователь не авторизован.");
    }
  };

  const handleClosePopupShop = () => {
    setIsAnimated(false);
    setTimeout(() => {
      setPopupVisible(false);
    }, 300);
  };

  const handleSetInput = () => {
    if (currentUser && userData) {
      const name = currentUser.first_name;

      if (userData.counter === undefined || userData.counter === 3) {
        setInputHolder(`Задайте вопрос и жмите на карту`);
      } else {
        const message =
          userData.type === "premium"
            ? `${name}, задавайте вопросы!`
            : userData.counter <= 0
            ? `${name}, сегодня вопросы кончились.`
            : userData.counter === 1
            ? `${name}, еще 1 вопрос сегодня`
            : userData.counter === 2
            ? `${name}, еще ${userData.counter} вопроса сегодня`
            : `${name}, еще ${userData.counter} вопросов сегодня`;

        setInputHolder(message);
        if (userData.counter === 0) {
          handleShowAlert("Сегодня лимит запросов исчерпан");
        }
      }
    }
  };

  const handleScroll = () => {
    if (descriptionRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = descriptionRef.current;
      const isScrollable = scrollHeight > clientHeight;
      if (isScrollable && scrollHeight - scrollTop <= clientHeight + 1) {
        setUseMask(false);
      } else {
        setUseMask(true);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        setInputFocused(true);
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // useEffect(() => {
  //   if (isRequests === true) {
  //     console.log("now");
  //   }
  // }, [isRequests]);

  useEffect(() => {
    const currentRef = descriptionRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
      return () => {
        currentRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [descriptionRef]);

  useEffect(() => {
    if (isOver) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [isOver]);

  useEffect(() => {
    handleSetInput();
  }, [userData]);

  return (
    <div className={`deck ${isOver ? "deck-mingap" : ""}`}>
      <h1 className={`deck__title ${isOver ? "deck__title-smaller" : ""}`}>
        GIRLS WITH TAROT
      </h1>

      {isOver ? (
        <>
          <p className={`deck__text ${isVisible ? "deck__text-visible" : ""}`}>
            {inputQuestion.trim() === ""
              ? "Без контекста вопроса"
              : inputQuestion}
          </p>
          <div className={`cards ${isVisible ? "cards-visible" : ""}`}>
            {drawnCards.map((card, index) => (
              <div
                key={index}
                className={`card-cell ${isVisible ? "card-cell-visible" : ""}`}
              >
                <Card card={card} onClick={() => onCardClick(card)} />
              </div>
            ))}
          </div>
          {isLoading ? (
            <div
              className={`deck__loader ${
                isVisible ? "deck__loader-visible" : ""
              }`}
            ></div>
          ) : (
            drawnCards.length === 3 && (
              <div
                ref={descriptionRef}
                className={`description ${
                  isVisible ? "description-visible" : ""
                }`}
                style={{
                  maskImage: useMask
                    ? "linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)"
                    : "none",
                  WebkitMaskImage: useMask
                    ? "linear-gradient(to bottom, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%)"
                    : "none",
                }}
              >
                {aiDescription.split("\n\n").map((paragraph, index) => (
                  <p className="description-text" key={index}>
                    {paragraph}
                  </p>
                ))}
              </div>
            )
          )}
          {!isLoading && (
            <div
              className={`deck__buttons ${
                isVisible ? "deck__buttons-visible" : ""
              }`}
            >
              <button
                className="deck__button deck__button-dark"
                style={{ backgroundColor: buttonColor }}
                onClick={() => {
                  handleClickLeft();
                  isShowShop ? handleGetPrem() : handleSave();
                }}
              >
                {isShowShop ? "Купить доступ" : "Сохранить себе"}
              </button>
              <button
                style={{ backgroundColor: buttonColor }}
                onClick={() => {
                  handleClickRight();
                  onResetGame();
                }}
                className="deck__button"
              >
                Гадаем еще раз
              </button>
            </div>
          )}
        </>
      ) : (
        <>
          <form
            onSubmit={handleSubmit}
            className={`question__box ${
              !isVisible ? "question__box-visible" : ""
            }`}
          >
            <input
              type="text"
              ref={inputRef}
              value={inputQuestion}
              onChange={onInputChange}
              placeholder={inputHolder}
              className={`deck__input ${
                isButtonClicked ? "deck__input-clicked" : ""
              }`}
            />
            <button
              type="submit"
              className={`deck__button-input ${
                isButtonClicked ? "clicked" : ""
              }`}
            >
              <div className="deck__button-input_image"></div>
            </button>
          </form>
          <div className="deck-controls">
            <div className="top-card" onClick={onDrawCard}>
              {deck.length > 0 ? (
                <>
                  <div
                    className={`deck__card-back ${
                      !isVisible ? "deck__card-back-visible" : ""
                    } ${isCardClicked ? "deck__card-back_clicked" : ""}`}
                  ></div>
                  <div
                    className={`deck__card-back-z ${
                      isCardClicked ? "deck__card-back-z_shown" : ""
                    }`}
                  >
                    <img
                      className="deck__card-image"
                      src={shownCard.URL}
                      alt={shownCard.Name}
                    />
                    <p className="deck__card-title">{shownCard.Name}</p>
                  </div>
                </>
              ) : (
                "Карт больше нет"
              )}
            </div>
          </div>
        </>
      )}
      {currentCard && <PopupCard card={currentCard} onClose={onClosePopup} />}
      {isPopupVisible && (
        <PopupShop
          className={`${isAnimated ? "popup-shop_visible" : ""}`}
          onClose={handleClosePopupShop}
          handleBuyPremium={handleBuyPremium}
          handleStarPremium={handleStarPremium}
          userEmail={email}
        />
      )}
      {isAlertVisible && (
        <PopupAlert
          alertMessage={alertMessage}
          className={`${isAnimatedAlert ? "popup-alert_visible" : ""}`}
        />
      )}
      <NavMenu />
    </div>
  );
};

export default Deck;
