import fool from "../images/shut.jpg";
import magician from "../images/mag.jpg";
import priestess from "../images/verhovaya-zhrica.jpg";
import empress from "../images/imperatrica.jpg";
import emperor from "../images/imperator.jpg";
import herophant from "../images/verhovnyi-zhrec.jpg";
import lovers from "../images/vlublennye.jpg";
import chariot from "../images/kolesnica.jpg";
import strength from "../images/sila.jpg";
import hermit from "../images/otshelnik.jpg";
import fortune from "../images/koleso-fortuny.jpg";
import justice from "../images/spravedlivost.jpg";
import hungedman from "../images/poveshennyi.jpg";
import death from "../images/smert.jpg";
import temperance from "../images/umerennost.jpg";
import devil from "../images/diyavol.jpg";
import tower from "../images/padayushaya-bashnya.jpg";
import star from "../images/zvezda.jpg";
import moon from "../images/luna.jpg";
import sun from "../images/solnce.jpg";
import judgement from "../images/strashnyi-sud.jpg";
import world from "../images/mir.jpg";

import acerod from "../images/26.jpg";
import rod2 from "../images/27.jpg";
import rod3 from "../images/28.jpg";
import rod4 from "../images/29.jpg";
import rod5 from "../images/30.jpg";
import rod6 from "../images/31.jpg";
import rod7 from "../images/32.jpg";
import rod8 from "../images/33.jpg";
import rod9 from "../images/34.jpg";
import rod10 from "../images/35.jpg";
import pagerod from "../images/25.jpg";
import knightrod from "../images/24.jpg";
import queenrod from "../images/23.jpg";
import kingrod from "../images/22.jpg";

import acecup from "../images/40.jpg";
import cup2 from "../images/41.jpg";
import cup3 from "../images/42.jpg";
import cup4 from "../images/43.jpg";
import cup5 from "../images/44.jpg";
import cup6 from "../images/45.jpg";
import cup7 from "../images/46.jpg";
import cup8 from "../images/47.jpg";
import cup9 from "../images/48.jpg";
import cup10 from "../images/49.jpg";
import pagecup from "../images/39.jpg";
import knightcup from "../images/38.jpg";
import queencup from "../images/37.jpg";
import kingcup from "../images/36.jpg";

import acepentacle from "../images/54.jpg";
import pentacle2 from "../images/55.jpg";
import pentacle3 from "../images/56.jpg";
import pentacle4 from "../images/57.jpg";
import pentacle5 from "../images/58.jpg";
import pentacle6 from "../images/59.jpg";
import pentacle7 from "../images/60.jpg";
import pentacle8 from "../images/61.jpg";
import pentacle9 from "../images/62.jpg";
import pentacle10 from "../images/63.jpg";
import pagepentacle from "../images/53.jpg";
import knightpentacle from "../images/52.jpg";
import queenpentacle from "../images/51.jpg";
import kingpentacle from "../images/50.jpg";

import acesword from "../images/68.jpg";
import sword2 from "../images/69.jpg";
import sword3 from "../images/70.jpg";
import sword4 from "../images/71.jpg";
import sword5 from "../images/72.jpg";
import sword6 from "../images/73.jpg";
import sword7 from "../images/74.jpg";
import sword8 from "../images/75.jpg";
import sword9 from "../images/76.jpg";
import sword10 from "../images/77.jpg";
import pageseword from "../images/67.jpg";
import knightsword from "../images/66.jpg";
import queensword from "../images/65.jpg";
import kingsword from "../images/64.jpg";

export const BASE_URL = "https://api.webtarot.ru";

const tarotDeckData = [
  {
    id: 0,
    Name: "Шут",
    Description: "Открытость, любознательность, легкомыслие, неопределенность",
    URL: fool,
  },
  {
    id: 1,
    Name: "Маг",
    Description:
      "Активная воля, энергетика, вдохновение, работа на себя, ясность ума",
    URL: magician,
  },
  {
    id: 2,
    Name: "Жрица",
    Description: "Женское начало, гармония, терпение, тайные дела",
    URL: priestess,
  },

  {
    id: 3,
    Name: "Императрица",
    Description: "Плодородие, рождение, воспитание, духовная сила",
    URL: empress,
  },
  {
    id: 4,
    Name: "Император",
    Description: "Превосходство разума, лидерство, амбиции, авторитет",
    URL: emperor,
  },
  {
    id: 5,
    Name: "Жрец",
    Description: "Мудрость, традиции, духовный лидер, готовность к свадьбе",
    URL: herophant,
  },
  {
    id: 6,
    Name: "Влюбленные",
    Description: "Выбор, союз, доверие, здоровье, чувства",
    URL: lovers,
  },
  {
    id: 7,
    Name: "Колесница",
    Description: "Успех, победа, цель, рывок вперед",
    URL: chariot,
  },
  {
    id: 8,
    Name: "Сила",
    Description: "Женское начало, духовная сила, эмоциональный подъем, доверие",
    URL: strength,
  },
  {
    id: 9,
    Name: "Отшельник",
    Description:
    "Путь сознания, мудрость, открытия, духовный рост",    URL: hermit,
  },
  {
    id: 10,
    Name: "Колесо Фортуны ",
    Description: "Важное решение, уверенность в удаче, азарт",
    URL: fortune,
  },
  {
    id: 11,
    Name: "Справедливость",
    Description: "Карма, баланс, ответственность, честность",
    URL: justice,
  },
  {
    id: 12,
    Name: "Повешенный",
    Description: "Учение, урок, тупик, опыт, озарение",
    URL: hungedman,
  },
  {
    id: 13,
    Name: "Смерть",
    Description: "Освобождение, расставание, пробуждение, новый период",
    URL: death,
  },
  {
    id: 14,
    Name: "Умеренность",
    Description: "Баланс, равновесие, компромисс, дружба, доверие",
    URL: temperance,
  },
  {
    id: 15,
    Name: "Дьявол",
    Description: "Искушения, тщеславие, эгоизм, садизм",
    URL: devil,
  },
  {
    id: 16,
    Name: "Башня",
    Description: "Полный крах, очищение души, конфликт, прозрение",
    URL: tower,
  },
  {
    id: 17,
    Name: "Звезда",
    Description: "Надежда, вдохновение, образование, духовность, творчество",
    URL: star,
  },
  {
    id: 18,
    Name: "Луна",
    Description: "Мечта, страх, неуверенность в себе, обман",
    URL: moon,
  },
  {
    id: 19,
    Name: "Солнце",
    Description: "Ясный ум, радость, счастье, процветание",
    URL: sun,
  },
  {
    id: 20,
    Name: "Суд",
    Description: "Перерождение, выбор, конец эры, успех",
    URL: judgement,
  },
  {
    id: 21,
    Name: "Мир",
    Description: "Свобода, триумф, достижение, призвание",
    URL: world,
  },
  {
    id: 22,
    Name: "Туз жезлов",
    Description: "Новые начинания, наследство, перемены, возможности, сила",
    URL: acerod,
  },
  {
    id: 23,
    Name: "II Жезлов",
    Description: "Интерес, нейтральная позиция, пустота, союз без тепла",
    URL: rod2,
  },
  {
    id: 24,
    Name: "III Жезлов",
    Description: "Вершина, труд, материальное благополучие, поддержка",
    URL: rod3,
  },
  {
    id: 25,
    Name: "IV Жезлов",
    Description: "Гармония, мир, процветание, переезд",
    URL: rod4,
  },
  {
    id: 26,
    Name: "V Жезлов",
    Description: "Испытание, борьба, спор, задача, загадка",
    URL: rod5,
  },
  {
    id: 27,
    Name: "VI Жезлов",
    Description: "Успех, завершение, лидерские качества, мудрые решения",
    URL: rod6,
  },
  {
    id: 28,
    Name: "VII Жезлов",
    Description: "Мужество, критика, стойкость, напасть",
    URL: rod7,
  },
  {
    id: 29,
    Name: "VIII Жезлов",
    Description: "Зеленый свет, новые друзья, творческий прорыв, прогресс",
    URL: rod8,
  },
  {
    id: 30,
    Name: "IX Жезлов",
    Description: "Умпрямство, спокойствие, готовность к защите, финансовый успех",
    URL: rod9,
  },
  {
    id: 31,
    Name: "X Жезлов",
    Description: "Решительность окупится, ненужный груз, измена, скрытность",
    URL: rod10,
  },
  {
    id: 32,
    Name: "Паж Жезлов",
    Description: "Молодой человек, танцор, энтузиазм, смелость",
    URL: pagerod,
  },
  {
    id: 33,
    Name: "Рыцарь Жезлов",
    Description: "Риск, нетерпение, крайности, энтузиазм",
    URL: knightrod,
  },
  {
    id: 34,
    Name: "Королева Жезлов",
    Description: "Успех, строгость, справедливость, любовь к роскоши",
    URL: queenrod,
  },
  {
    id: 35,
    Name: "Король Жезлов",
    Description: "Гордость, честолюбие, активность, быстрая реакция",
    URL: kingrod,
  },
  {
    id: 36,
    Name: "Туз Кубков",
    Description: "Открытия, подарок, дар судьбы, влюбленность",
    URL: acecup,
  },
  {
    id: 37,
    Name: "II Кубков",
    Description: "Дружба, любовь, примирение, рождение ребенка",
    URL: cup2,
  },
  {
    id: 38,
    Name: "III Кубков",
    Description: "Успех, дело, любовь, дружба, радость",
    URL: cup3,
  },
  {
    id: 39,
    Name: "IV Кубков",
    Description: "Обида, неуверенность, апатия, замкнутость",
    URL: cup4,
  },
  {
    id: 40,
    Name: "V Кубков",
    Description: "Печаль, провал, проблемы в семье, выкидыш",
    URL: cup5,
  },
  {
    id: 41,
    Name: "VI Кубков",
    Description: "Уют, покой, старый друг, дети, годовщина",
    URL: cup6,
  },
  {
    id: 42,
    Name: "VII Кубков",
    Description: "Трудности, ошибочный выбор, творческий прорыв",
    URL: cup7,
  },
  {
    id: 43,
    Name: "VIII Кубков",
    Description: "Разочарование, неуверенность, скромность, честность",
    URL: cup8,
  },
  {
    id: 44,
    Name: "IX Кубков",
    Description: "Достаток, успех, излишества, праздник, брак",
    URL: cup9,
  },
  {
    id: 45,
    Name: "X Кубков",
    Description: "Счастье, семья, душевное спокойствие, начало",
    URL: cup10,
  },
  {
    id: 46,
    Name: "Паж Кубков",
    Description: "Хорошие новости, импульс, шанс, толчок",
    URL: pagecup,
  },
  {
    id: 47,
    Name: "Рыцарь Кубков",
    Description: "Внушение, скрытое намерение, настроение, розовые очки",
    URL: knightcup,
  },
  {
    id: 48,
    Name: "Королева Кубков",
    Description: "Успех, покой, сдержанность, воображение",
    URL: queencup,
  },
  {
    id: 49,
    Name: "Король Кубков",
    Description: "Эмпатия, любезность, воображение, элегантность",
    URL: kingcup,
  },
  {
    id: 50,
    Name: "Туз Пентаклей",
    Description: "Процветание, независимость, удача, похвала, личные достижения",
    URL: acepentacle,
  },
  {
    id: 51,
    Name: "II Пентаклей",
    Description: "Хаотичность, эмоции, ловкость, безразличие, поездка",
    URL: pentacle2,
  },
  {
    id: 52,
    Name: "III Пентаклей",
    Description: "Решающий шаг, помощь, уверенность, удовольствие",
    URL: pentacle3,
  },
  {
    id: 53,
    Name: "IV Пентаклей",
    Description: "Алчность, скупость, подарок, наследство",
    URL: pentacle4,
  },
  {
    id: 54,
    Name: "V Пентаклей",
    Description: "Неприятности, потери, невезение, нет почвы под ногами",
    URL: pentacle5,
  },
  {
    id: 55,
    Name: "VI Пентаклей",
    Description: "Благотворительность, помощь, гордливость, наставничество",
    URL: pentacle6,
  },
  {
    id: 56,
    Name: "VII Пентаклей",
    Description: "Терпение, медленный рост, беременность, богатство",
    URL: pentacle7,
  },
  {
    id: 57,
    Name: "VIII Пентаклей",
    Description: "Успех, работа, занятость, удача",
    URL: pentacle8,
  },
  {
    id: 58,
    Name: "IX Пентаклей",
    Description: "Завершение работы, успех, труд, любовь к природе",
    URL: pentacle9,
  },
  {
    id: 59,
    Name: "X Пентаклей",
    Description: "Мудрость, успех, стабильность, дом",
    URL: pentacle10,
  },
  {
    id: 60,
    Name: "Паж Пентаклей",
    Description: "Шанс, усердие, изучение, доброта, решительность",
    URL: pagepentacle,
  },
  {
    id: 61,
    Name: "Рыцарь Пентаклей",
    Description: "Надежность, удача, терпение, выносливость",
    URL: knightpentacle,
  },
  {
    id: 62,
    Name: "Королева Пентаклей",
    Description: "Доброта, трудолюбие, сердечность, верность",
    URL: queenpentacle,
  },
  {
    id: 63,
    Name: "Король Пентаклей",
    Description: "Инстинкт, ум, хорошее чутье, терпеливость",
    URL: kingpentacle,
  },
  {
    id: 64,
    Name: "Туз Мечей",
    Description: "Решение проблемы, завоевание, свобода, решительность",
    URL: acesword,
  },
  {
    id: 65,
    Name: "II Мечей",
    Description: "Сдерживание эмоций, дружба, любовь, стойкость",
    URL: sword2,
  },
  {
    id: 66,
    Name: "III Мечей",
    Description: "Разбитое сердце, конфликт, разрыв, неприятности",
    URL: sword3,
  },
  {
    id: 67,
    Name: "IV Мечей",
    Description: "Успокоение, застой, путешествие, самосозерцание",
    URL: sword4,
  },
  {
    id: 68,
    Name: "V Мечей",
    Description: "Позор, неудача, потеря, трусость",
    URL: sword5,
  },
  {
    id: 69,
    Name: "VI Мечей",
    Description: "Перемены, любопытство, шанс на удачу, путешествие",
    URL: sword6,
  },
  {
    id: 70,
    Name: "VII Мечей",
    Description: "Хитрость, провал, переезд, предательство",
    URL: sword7,
  },
  {
    id: 71,
    Name: "VIII Мечей",
    Description: "Слабость, препятствия, убеждения, ожидание неудачи",
    URL: sword8,
  },
  {
    id: 72,
    Name: "IX Мечей",
    Description: "Страдания, сомнения, стыд, вина, событие",
    URL: sword9,
  },
  {
    id: 73,
    Name: "X Мечей",
    Description: "Отчаяние, утрата, стресс, бедствие, грабеж",
    URL: sword10,
  },
  {
    id: 74,
    Name: "Паж Мечей",
    Description: "Шанс, возможность, начало конца, честность, решимость",
    URL: pageseword,
  },
  {
    id: 75,
    Name: "Рыцарь Мечей",
    Description: "Упорство, порывистость, храбрость, гнев, защита",
    URL: knightsword,
  },
  {
    id: 76,
    Name: "Королева Мечей",
    Description: "Проницательность, сарказм, карьера, амбиции, печаль",
    URL: queensword,
  },
  {
    id: 77,
    Name: "Король Мечей",
    Description: "Ответственность, контроль, агрессивность, властность",
    URL: kingsword,
  },
];

export default tarotDeckData;
