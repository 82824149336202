import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import Deck from "./components/Deck/Deck.jsx";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  replace,
} from "react-router-dom";
import Library from "./components/Library/Library.jsx";
import CurrentUserContext from "./context/CurrentUserContext.js";
import tarotDeckData from "./utils/constances.js";
import GigaApi from "./utils/GigaApi.js";
import Unauthorized from "./components/Unauthorized/Unauthorized.jsx";
import ProtectedRouteElement from "./components/ProtectedRouteElement/ProtectedRouteElement.jsx";
import * as MainApi from "./utils/MainApi.js";

const App = () => {
  const [currentUser, setCurrentUser] = useState({
    username: "",
    first_name: "",
    id: "",
  });
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deck, setDeck] = useState(tarotDeckData);
  const [drawnCards, setDrawnCards] = useState([]);
  const [inputQuestion, setInputQuestion] = useState("");
  const [descriptionVisible, setDescriptionVisible] = useState(null);
  const [aiDescription, setAiDescription] = useState("");
  const [shuffled, setShuffled] = useState(false);
  const [currentCard, setCurrentCard] = useState(null);
  // const [cards, setCards] = useState(Array(5).fill(true));
  const [cardNames, setCardNames] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [isShuffling, setIsShuffling] = useState(false);
  const [isOver, setIsOver] = useState(false);

  const [isButtonClicked, setIsButtonClicked] = useState(false); // Нажата ли кнопка
  const [isCardClicked, setIsCardClicked] = useState(false); // Кликнута ли карта
  const [isDrawing, setIsDrawing] = useState(false); //Выкладываем ли карту

  const [isShowShop, setIsShowShop] = useState(true); // предложить подпиську

  const [isRequests, setIsRequests] = useState(false); // остались запросы?

  const [shownCard, setShownCard] = useState({});

  const [userData, setUserData] = useState({});
  const [email, setEmail] = useState("example@mail.ru");
  const inputRef = useRef(null);

  function handleLogin(username, id, data, hash) {
    const name = username && username.trim() ? username : "anonymous";

    MainApi.login(name, id, data, hash)
      .then((resp) => {
        setIsLoggedIn(true);
        if (resp.user) {
          const user = resp.user;
          const req = user.requestsToday;
          const type = user.userType;
          setUserData({ counter: req, type: type });
        }
        if (resp.user.email) {
          const mail = resp.user.email;
          setEmail(mail);
        }
      })
      .catch((err) => {
        console.log(err);
        navigate("/unauthorized", { replace: true });
      });
  }

  function shuffleDeck() {
    const shuffledDeck = [...tarotDeckData].sort(() => Math.random() - 0.5);
    setDeck(shuffledDeck);
    // setIsShuffling(true);
    // setTimeout(() => {
    //   setCards([...Array(5).fill(true)].sort(() => Math.random() - 0.5));
    //   setIsShuffling(false);
    // }, 1000);
    setShuffled(true);
  }

  const drawCard = () => {
    if (isDrawing) return;
    setIsDrawing(true);
    if (deck.length > 0 && drawnCards.length < 3) {
      const newCard = deck[0];
      setShownCard(newCard);
      setDrawnCards([...drawnCards, newCard]);
      setDeck(deck.slice(1));
    }
    setTimeout(() => {
      setIsCardClicked(true);
    }, 60);
    setTimeout(() => {
      setIsCardClicked(false);
      setIsDrawing(false);
      setShownCard({});
    }, 2000);
  };

  const handleCardClick = (card) => {
    setCurrentCard(card);
  };

  const closePopup = () => {
    setCurrentCard(null);
  };

  const getAllDescriptions = async () => {
    const cardsData = drawnCards.map((card) => card.Name).join(", ");
    // const cardsDescription = drawnCards
    //   .map((card) => card.Description)
    //   .join(" ");
    const question = inputQuestion || "Расклад без вопроса";
    if (isLoggedIn) {
      //PROD
      setIsLoading(true);
      try {
        const interpretation = await GigaApi.getInterpretation(
          currentUser,
          cardsData,
          question
        );
        const description = interpretation.resp.choices[0].message.content;
        const counter = interpretation.userData;
        setUserData({ counter: counter.counter, type: counter.type });
        setCardNames(cardsData);
        setAiDescription(description);
      } catch (err) {
        if (err === 403) {
          setIsShowShop(true);
          setAiDescription(
            `Достигнут дневной лимит трактовок.\n\nВы можете продолжать расклады, трактуя их самостоятельно, либо поддержать нас, оформив доступ к приложению без ограничений на 14 или 30 дней.`
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = (event, inputRef) => {
    event.preventDefault();
    setIsCardClicked(true);
    setInputQuestion(inputQuestion);
    setIsButtonClicked(true);

    if (inputRef.current) {
      inputRef.current.blur();
    }

    setTimeout(() => {
      setIsButtonClicked(false);
      setIsCardClicked(false);
    }, 200);
  };

  const onInputChange = (event) => {
    setInputQuestion(event.target.value);
  };

  const handleClearInput = () => {
    setInputQuestion("");
  };

  const resetGame = () => {
    setIsOver(false);
    setDrawnCards([]);
    setShuffled(false);
    setDescriptionVisible(null);
    setAiDescription("");
    shuffleDeck();
    // setIsShuffling(false);
    handleClearInput();
    setIsShowShop(false);
    // console.log(userData.counter)
    // if (userData.counter <= 0) {
    userData.counter === undefined ? setIsRequests(true) : setIsRequests(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-web-app.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        resetGame();
        const tg = window.Telegram.WebApp;
        if (tg.initDataUnsafe && tg.initDataUnsafe.user) {
          setCurrentUser({
            username: tg.initDataUnsafe.user.username,
            first_name: tg.initDataUnsafe.user.first_name,
            id: tg.initDataUnsafe.user.id,
          });
          handleLogin(
            tg.initDataUnsafe.user.username,
            tg.initDataUnsafe.user.id,
            tg.initData,
            tg.initDataUnsafe.hash
          );
        } else {
          console.warn("User data is not available.");
        }
        setTimeout(() => {
          if (!tg.isExpanded) {
            tg.expand();
          }
        }, 100);
      } else {
        console.warn("Telegram WebApp is not available.");
      }
      setLoading(false);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (drawnCards.length === 3) {
      setTimeout(() => {
        setIsOver(true);
      }, 1200);
      getAllDescriptions();
    }
  }, [drawnCards]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoggedIn) {
        navigate("/", { replace: true });
      }
    }, 3000);
  }, [isLoggedIn]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className="App">
        <div className="App-background">
          <div className="fog"></div>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRouteElement
                  path="/"
                  element={Deck}
                  isLoggedIn={isLoggedIn}
                  deck={deck}
                  drawnCards={drawnCards}
                  inputQuestion={inputQuestion}
                  descriptionVisible={descriptionVisible}
                  aiDescription={aiDescription}
                  // shuffled={shuffled}
                  currentCard={currentCard}
                  isLoading={isLoading}
                  // onShuffleDeck={shuffleDeck}
                  onDrawCard={drawCard}
                  onResetGame={resetGame}
                  onCardClick={handleCardClick}
                  onClosePopup={closePopup}
                  onClearInput={handleClearInput}
                  cardNames={cardNames}
                  getAllDescriptions={getAllDescriptions}
                  isOver={isOver}
                  handleSubmit={(event) => handleSubmit(event, inputRef)}
                  onInputChange={onInputChange}
                  isButtonClicked={isButtonClicked}
                  userData={userData}
                  inputRef={inputRef}
                  isCardClicked={isCardClicked}
                  shownCard={shownCard}
                  isShowShop={isShowShop}
                  email={email}
                  isRequests={isRequests}
                />
              }
            />
            <Route
              path="/library"
              element={<ProtectedRouteElement element={Library} />}
            />
            <Route path="/unauthorized" element={<Unauthorized />}></Route>
            <Route
              path="*"
              element={
                isLoggedIn ? (
                  <Navigate to="/" replace />
                ) : (
                  <Navigate to="/unauthorized" replace />
                )
              }
            />
          </Routes>
        </div>
      </div>
    </CurrentUserContext.Provider>
  );
};

export default App;
