import React, { useEffect, useState } from "react";

const EmailForm = ({ onClose, payload, handleClose, handleBuyPremium, userEmail }) => {
    const [email, setEmail] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState("");
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    const handleCloseShop = () => {
        onClose();
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleGetInvoice = (event) => {
        event.preventDefault();
        if (!validateEmail(email)) {
            setError("Введите корректный email");
            return;
        }
        console.log("Email:", email);
        console.log("Checkbox checked:", isChecked);
        console.log("Payload", payload);
        handleBuyPremium(payload, email, isChecked);
        setError("");
        handleClose();
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < 550) {
                setIsKeyboardOpen(true);
            } else {
                setIsKeyboardOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <form className="form" onSubmit={handleGetInvoice}>
            <button
                className="popup-shop__close-button"
                onClick={handleCloseShop}
            ></button>
            <div className="form__title-box">
                <p className="form__title">{payload === "twoWeeksSubscriptionPayload" ? "Укусить яблоко" : "Съешь меня"}</p>
                <p className="form__title form__title_larger">{payload === "twoWeeksSubscriptionPayload" ? "149р / 14 дней" : "250р / 30 дней"}</p>
            </div>
            <p className={`popup__text ${error ? "form__error" : ""}`}>
                {error ? error : "Email"}
            </p>
            <input
                type="text"
                className="form__input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={userEmail}
            />
            <div className="form__checkbox-box">
                <label className="form__checkbox_custom">
                    <input
                        type="checkbox"
                        name="emailsaver"
                        id=""
                        className="form__checkbox"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.target.checked)}
                    />
                    <span className="form__checkmark"></span>
                </label>
                <p className="popup__text">Запомнить почту</p>
            </div>
            <p className="popup__text popup__text_larger">
                {`Никакого спама,\n это необходимо, чтобы прислать чек`}
            </p>
            <button
                type="submit"
                className={`form__button ${isKeyboardOpen ? 'hidden' : ''}`}
            >
                Прислать счет
            </button>
        </form>
    );
};

export default EmailForm;
